import dayjs from "dayjs";
import { apiRequest } from "../hooks/apiRequest";
import { auth } from "./auth";
import { request } from "./request";
import React from "react";

export const toast = (mensaje, type = "success") => {
	let toast = document.createElement("DIV");
	let text = document.createTextNode(mensaje);
	toast.classList.add("toast");
	toast.classList.add(type);
	toast.classList.add("animated");
	toast.classList.add("fast");
	toast.classList.add("fadeIn");
	toast.appendChild(text);
	document.getElementsByTagName("body")[0].appendChild(toast);

	setTimeout(() => {
		toast.classList.remove("fadeIn");
		toast.classList.add("fadeOut");
		setTimeout(() => {
			toast.remove();
		}, 1000);
	}, 3000);
};

export const formatCurrency = (
	amount,
	prefix = "$",
	decimalCount = 2,
	decimal = ".",
	thousands = ","
) => {
	try {
		decimalCount = Math.abs(decimalCount);
		decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

		const negativeSign = amount < -0.01 ? "-" : "";

		let i = parseInt(
			(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
		).toString();
		let j = i.length > 3 ? i.length % 3 : 0;

		if (prefix === "DOP") {
			prefix = "RD$";
		}

		if (prefix === "USD") {
			prefix = "USD$";
		}

		return (
			negativeSign +
			prefix +
			(j ? i.substr(0, j) + thousands : "") +
			i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
			(decimalCount
				? decimal +
				  Math.abs(amount - i)
						.toFixed(decimalCount)
						.slice(2)
				: "")
		);
	} catch (e) {
		console.log(e);
	}
};

export const getMoneda = (moneda) => {
	switch (moneda) {
		case "USD":
			return "Dólares";

		default:
			return "Pesos";
	}
};

export const formatNumber = (number) => {
	return parseFloat(number)
		.toFixed(2)
		.replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const getEstadoVehiculo = (estado) => {
	const labels = getEstadosVehiculo();

	return labels[estado];
};

export const getEstadosVehiculo = () => {
	return {
		activo: "Activo",
		reparacion: "Reparación",
		inactivo: "Inactivo",
		desuso: "Desuso",
	};
};

export const isMecanico = (cargo) => {
	if (!cargo) return false;
	cargo = cargo
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.toLowerCase();

	return cargo.indexOf("mecanico") > -1;
};

export const formatDate = (date) => {
	const parsedDate =
		Object.prototype.toString.call(date) === "date" ? date : new Date(date);

	const day = parsedDate.getDate().toString().padStart(2, 0);
	const month = (parsedDate.getMonth() + 1).toString().padStart(2, 0);
	const year = parsedDate.getFullYear();

	return `${year}-${month}-${day}`;
};

export const formatDateTime = (date) => {
	return dayjs(date).format("DD/MM/YYYY hh:mm a");
};

export const formatHumanReadableDate = (date) => {
	if (!date) return null;

	if (date instanceof Date) return date.toLocaleDateString("es-ES");

	date = new Date(date);

	return date.toLocaleDateString("es-ES", {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
	});
};

export const firstDayOfMonth = () => {
	const parsedDate = new Date();
	return formatDate(
		new Date(parsedDate.getFullYear(), parsedDate.getMonth(), 1)
	);
};

export const getMiddleDate = () => {
	const currentDate = new Date();

	if (currentDate.getDate() > 15) {
		return (
			currentDate.getFullYear() +
			"-" +
			String(currentDate.getMonth() + 1).padStart(2, "0") +
			"-" +
			"16"
		);
	} else {
		return (
			currentDate.getFullYear() +
			"-" +
			String(currentDate.getMonth() + 1).padStart(2, "0") +
			"-" +
			"15"
		);
	}
};

export const lastDayOfMonth = () => {
	const parsedDate = new Date();
	return formatDate(
		new Date(parsedDate.getFullYear(), parsedDate.getMonth() + 1, 0)
	);
};

export const getCurrentMonthName = (month) => {
	const months = [
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	];

	if (month === undefined) {
		return months[new Date().getMonth()];
	}

	return months[month];
};

export const getCurrentDayName = (day) => {
	const days = [
		"Domingo",
		"Lunes",
		"Martes",
		"Miércoles",
		"Jueves",
		"Viernes",
		"Sábado",
	];

	return days[day];
};

export const getEstadoServicio = (estado) => {
	const estados = {
		"por-vencer": "Por vencer",
		"a-tiempo": "A tiempo",
		vencidos: "Vencidos",
		todos: "Todos",
	};

	return estados[estado];
};

export const getDiaProgramacion = (dia) => {
	const nombreAsignacionDiaria = [
		"LUNES Y JUEVES",
		"MARTES Y VIERNES",
		"MIÉRCOLES Y SÁBADOS",
		"LUNES Y JUEVES",
		"MARTES Y VIERNES",
		"MIÉRCOLES Y SÁBADOS",
		"DOMINGOS",
	];

	return nombreAsignacionDiaria[dia];
};

export const getLink = (location) => {
	const sucursal = getSucursal();
	return `/${sucursal}${location}`;
};

export const getSucursal = () => {
	let sucursal = parseInt(document.URL.split("/")[3]);

	const user = auth.getUser();

	//Confirmar que el usuario tiene acceso a la sucursal.
	const sucursalesDisponibles = user.sucursales.filter((suc) => {
		return suc.id === sucursal;
	});

	if (!sucursal || sucursalesDisponibles.length === 0) {
		return parseInt(window.localStorage.getItem("sucursalActual"));
	}

	return sucursal;
};

export const unidades = [
    { value: "UND", label: "UND" },
    { value: "PAQUETE", label: "PAQUETE" },
    { value: "JUEGO", label: "JUEGO" },
    { value: "PIES", label: "PIES" },
    { value: "PULG", label: "PULG" },
    { value: "MTS", label: "MTS" },
    { value: "CM", label: "CM" },
    { value: "GLS", label: "GLS" },
    { value: "LATA", label: "LATA" },
    { value: "CUBETA", label: "CUBETA" },
    { value: "CUARTOS", label: "CUARTOS" },
    { value: "LITROS", label: "LITROS" },
    { value: "LBS", label: "LBS" },
    { value: "KGS", label: "KGS" },
];

export const bancos = [
	{ label: "Banco Popular Dominicano", value: "Banco Popular Dominicano" },
	{ label: "Banco BHD León", value: "Banco BHD León" },
	{ label: "Banco Vimenca", value: "Banco Vimenca" },
	{ label: "Banco Santa Cruz", value: "Banco Santa Cruz" },
	{ label: "Banco Caribe", value: "Banco Caribe" },
	{ label: "Banco BDI", value: "Banco BDI" },
	{ label: "Banco López de Haro", value: "Banco López de Haro" },
	{ label: "Banco Ademi", value: "Banco Ademi" },
	{ label: "Banco BELLBANK", value: "Banco BELLBANK" },
	{
		label: "Banco Múltiple Activo Dominicana",
		value: "Banco Múltiple Activo Dominicana",
	},
	{ label: "Banco de Reservas/Banreservas", value: "Banco de reservas" },
	{ label: "Banco JMJ", value: "Banco JMJ" },
	{ label: "Scotiabank", value: "Scotiabank" },
	{ label: "Citibank", value: "Citibank" },
	{ label: "Banco Promerica", value: "Banco Promerica" },
	{ label: "Banesco", value: "Banesco" },
	{ label: "Bancamerica", value: "Bancamerica" },
	{ label: "Asociación Popular", value: "Asociación Popular" },
	{
		label: "Asociación Popular de Ahorros y Préstamos",
		value: "Asociación Popular de Ahorros y Préstamos",
	},
	{ label: "Asociación Cibao", value: "Asociación Cibao" },
	{ label: "Asociación Nortena", value: "Asociación Nortena" },
	{ label: "Asociación Peravia", value: "Asociación Peravia" },
	{ label: "Asociación Romana", value: "Asociación Romana" },
	{ label: "Asociación Higuamo", value: "Asociación Higuamo" },
	{ label: "Asociación La Vega Real", value: "Asociación La Vega Real" },
	{ label: "Asociación Duarte", value: "Asociación Duarte" },
	{ label: "Asociación Barahona", value: "Asociación Barahona" },
	{ label: "Asociación Maguana", value: "Asociación Maguana" },
	{ label: "Asociación Mocana", value: "Asociación Mocana" },
	{ label: "Asociación Bonao", value: "Asociación Bonao" },
	{ label: "Asociación La Nacional", value: "Asociación La Nacional" },
];

export const provinciasMunicipiosForDropdown = [
	{
		value: "Azua",
		label: "Azua",
	},
	{
		value: "Bahoruco",
		label: "Bahoruco",
	},
	{
		value: "Barahona",
		label: "Barahona",
	},
	{
		value: "Dajabón",
		label: "Dajabón",
	},
	{
		value: "Distrito Nacional",
		label: "Distrito Nacional",
	},
	{
		value: "Duarte",
		label: "Duarte",
	},
	{
		value: "Elías Piña",
		label: "Elías Piña",
	},
	{
		value: "El Seibo",
		label: "El Seibo",
	},
	{
		value: "Espaillat",
		label: "Espaillat",
	},
	{
		value: "Hato Mayor",
		label: "Hato Mayor",
	},
	{
		value: "Hermanas Mirabal",
		label: "Hermanas Mirabal",
	},
	{
		value: "Independencia",
		label: "Independencia",
	},
	{
		value: "La Altagracia",
		label: "La Altagracia",
	},
	{
		value: "La Romana",
		label: "La Romana",
	},
	{
		value: "La Vega",
		label: "La Vega",
	},
	{
		value: "María Trinidad Sánchez",
		label: "María Trinidad Sánchez",
	},
	{
		value: "Monseñor Nouel",
		label: "Monseñor Nouel",
	},
	{
		value: "Monte Cristi",
		label: "Monte Cristi",
	},
	{
		value: "Monte Plata",
		label: "Monte Plata",
	},
	{
		value: "Pedernales",
		label: "Pedernales",
	},
	{
		value: "Peravia",
		label: "Peravia",
	},
	{
		value: "Puerto Plata",
		label: "Puerto Plata",
	},
	{
		value: "Samaná",
		label: "Samaná",
	},
	{
		value: "Sánchez Ramírez",
		label: "Sánchez Ramírez",
	},
	{
		value: "San Cristóbal",
		label: "San Cristóbal",
	},
	{
		value: "San José de Ocoa",
		label: "San José de Ocoa",
	},
	{
		value: "San Juan",
		label: "San Juan",
	},
	{
		value: "San Pedro de Macorís",
		label: "San Pedro de Macorís",
	},
	{
		value: "Santiago",
		label: "Santiago",
	},
	{
		value: "Santiago De los Caballeros",
		label: "Santiago De los Caballeros",
	},
	{
		value: "Santiago Rodríguez",
		label: "Santiago Rodríguez",
	},
	{
		value: "Santo Domingo",
		label: "Santo Domingo",
	},
	{
		value: "Valverde",
		label: "Valverde",
	},
	{
		value: "Azua de Compostela",
		label: "Azua de Compostela",
	},
	{
		value: "Estebanía",
		label: "Estebanía",
	},
	{
		value: "Guayabal",
		label: "Guayabal",
	},
	{
		value: "Las Charcas",
		label: "Las Charcas",
	},
	{
		value: "Las Yayas de Viajama",
		label: "Las Yayas de Viajama",
	},
	{
		value: "Padre Las Casas",
		label: "Padre Las Casas",
	},
	{
		value: "Peralta",
		label: "Peralta",
	},
	{
		value: "Pueblo Viejo",
		label: "Pueblo Viejo",
	},
	{
		value: "Sabana Yegua",
		label: "Sabana Yegua",
	},
	{
		value: "Tábara Arriba",
		label: "Tábara Arriba",
	},
	{
		value: "Neiba",
		label: "Neiba",
	},
	{
		value: "Galván",
		label: "Galván",
	},
	{
		value: "Los Ríos",
		label: "Los Ríos",
	},
	{
		value: "Tamayo",
		label: "Tamayo",
	},
	{
		value: "Villa Jaragua",
		label: "Villa Jaragua",
	},
	{
		value: "Cabral",
		label: "Cabral",
	},
	{
		value: "El Peñón",
		label: "El Peñón",
	},
	{
		value: "Enriquillo",
		label: "Enriquillo",
	},
	{
		value: "Fundación",
		label: "Fundación",
	},
	{
		value: "Jaquimeyes",
		label: "Jaquimeyes",
	},
	{
		value: "La Ciénaga",
		label: "La Ciénaga",
	},
	{
		value: "Las Salinas",
		label: "Las Salinas",
	},
	{
		value: "Paraíso",
		label: "Paraíso",
	},
	{
		value: "Polo",
		label: "Polo",
	},
	{
		value: "Vicente Noble",
		label: "Vicente Noble",
	},
	{
		value: "El Pino",
		label: "El Pino",
	},
	{
		value: "Loma de Cabrera",
		label: "Loma de Cabrera",
	},
	{
		value: "Partido",
		label: "Partido",
	},
	{
		value: "Restauración",
		label: "Restauración",
	},
	{
		value: "San Francisco de Macorís",
		label: "San Francisco de Macorís",
	},
	{
		value: "Arenoso",
		label: "Arenoso",
	},
	{
		value: "Castillo",
		label: "Castillo",
	},
	{
		value: "Eugenio María de Hostos",
		label: "Eugenio María de Hostos",
	},
	{
		value: "Las Guáranas",
		label: "Las Guáranas",
	},
	{
		value: "Pimentel",
		label: "Pimentel",
	},
	{
		value: "Villa Riva",
		label: "Villa Riva",
	},
	{
		value: "Miches",
		label: "Miches",
	},
	{
		value: "Comendador",
		label: "Comendador",
	},
	{
		value: "Bánica",
		label: "Bánica",
	},
	{
		value: "El Llano",
		label: "El Llano",
	},
	{
		value: "Hondo Valle",
		label: "Hondo Valle",
	},
	{
		value: "Juan Santiago",
		label: "Juan Santiago",
	},
	{
		value: "Pedro Santana",
		label: "Pedro Santana",
	},
	{
		value: "Moca",
		label: "Moca",
	},
	{
		value: "Cayetano Germosén",
		label: "Cayetano Germosén",
	},
	{
		value: "Gaspar Hernández",
		label: "Gaspar Hernández",
	},
	{
		value: "Jamao al Norte",
		label: "Jamao al Norte",
	},
	{
		value: "Hato Mayor del Rey",
		label: "Hato Mayor del Rey",
	},
	{
		value: "El Valle",
		label: "El Valle",
	},
	{
		value: "Sabana de la Mar",
		label: "Sabana de la Mar",
	},
	{
		value: "Salcedo",
		label: "Salcedo",
	},
	{
		value: "Tenares",
		label: "Tenares",
	},
	{
		value: "Villa Tapia",
		label: "Villa Tapia",
	},
	{
		value: "Jimaní",
		label: "Jimaní",
	},
	{
		value: "Cristóbal",
		label: "Cristóbal",
	},
	{
		value: "Duvergé",
		label: "Duvergé",
	},
	{
		value: "La Descubierta",
		label: "La Descubierta",
	},
	{
		value: "Mella",
		label: "Mella",
	},
	{
		value: "Postrer Río",
		label: "Postrer Río",
	},
	{
		value: "Higüey",
		label: "Higüey",
	},
	{
		value: "San Rafael del Yuma",
		label: "San Rafael del Yuma",
	},
	{
		value: "Guaymate",
		label: "Guaymate",
	},
	{
		value: "Villa Hermosa",
		label: "Villa Hermosa",
	},
	{
		value: "La Concepción de La Vega",
		label: "La Concepción de La Vega",
	},
	{
		value: "Constanza",
		label: "Constanza",
	},
	{
		value: "Jarabacoa",
		label: "Jarabacoa",
	},
	{
		value: "Jima Abajo",
		label: "Jima Abajo",
	},
	{
		value: "Nagua",
		label: "Nagua",
	},
	{
		value: "Cabrera",
		label: "Cabrera",
	},
	{
		value: "El Factor",
		label: "El Factor",
	},
	{
		value: "Río San Juan",
		label: "Río San Juan",
	},
	{
		value: "Bonao",
		label: "Bonao",
	},
	{
		value: "Maimón",
		label: "Maimón",
	},
	{
		value: "Piedra Blanca",
		label: "Piedra Blanca",
	},
	{
		value: "Montecristi",
		label: "Montecristi",
	},
	{
		value: "Castañuela",
		label: "Castañuela",
	},
	{
		value: "Guayubín",
		label: "Guayubín",
	},
	{
		value: "Las Matas de Santa Cruz",
		label: "Las Matas de Santa Cruz",
	},
	{
		value: "Pepillo Salcedo",
		label: "Pepillo Salcedo",
	},
	{
		value: "Villa Vásquez",
		label: "Villa Vásquez",
	},
	{
		value: "Bayaguana",
		label: "Bayaguana",
	},
	{
		value: "Peralvillo",
		label: "Peralvillo",
	},
	{
		value: "Sabana Grande de Boyá",
		label: "Sabana Grande de Boyá",
	},
	{
		value: "Yamasá",
		label: "Yamasá",
	},
	{
		value: "Oviedo",
		label: "Oviedo",
	},
	{
		value: "Baní",
		label: "Baní",
	},
	{
		value: "Nizao",
		label: "Nizao",
	},
	{
		value: "Altamira",
		label: "Altamira",
	},
	{
		value: "Guananico",
		label: "Guananico",
	},
	{
		value: "Imbert",
		label: "Imbert",
	},
	{
		value: "Los Hidalgos",
		label: "Los Hidalgos",
	},
	{
		value: "Luperón",
		label: "Luperón",
	},
	{
		value: "Sosúa",
		label: "Sosúa",
	},
	{
		value: "Villa Isabela",
		label: "Villa Isabela",
	},
	{
		value: "Villa Montellano",
		label: "Villa Montellano",
	},
	{
		value: "Las Terrenas",
		label: "Las Terrenas",
	},
	{
		value: "Sánchez",
		label: "Sánchez",
	},
	{
		value: "Bajos de Haina",
		label: "Bajos de Haina",
	},
	{
		value: "Cambita Garabito",
		label: "Cambita Garabito",
	},
	{
		value: "Los Cacaos",
		label: "Los Cacaos",
	},
	{
		value: "Sabana Grande de Palenque",
		label: "Sabana Grande de Palenque",
	},
	{
		value: "San Gregorio de Nigua",
		label: "San Gregorio de Nigua",
	},
	{
		value: "Villa Altagracia",
		label: "Villa Altagracia",
	},
	{
		value: "Yaguate",
		label: "Yaguate",
	},
	{
		value: "Rancho Arriba",
		label: "Rancho Arriba",
	},
	{
		value: "Sabana Larga",
		label: "Sabana Larga",
	},
	{
		value: "San Juan de la Maguana",
		label: "San Juan de la Maguana",
	},
	{
		value: "Bohechío",
		label: "Bohechío",
	},
	{
		value: "El Cercado",
		label: "El Cercado",
	},
	{
		value: "Juan de Herrera",
		label: "Juan de Herrera",
	},
	{
		value: "Las Matas de Farfán",
		label: "Las Matas de Farfán",
	},
	{
		value: "Vallejuelo",
		label: "Vallejuelo",
	},
	{
		value: "Consuelo",
		label: "Consuelo",
	},
	{
		value: "Guayacanes",
		label: "Guayacanes",
	},
	{
		value: "Quisqueya",
		label: "Quisqueya",
	},
	{
		value: "Ramón Santana",
		label: "Ramón Santana",
	},
	{
		value: "San José de Los Llanos",
		label: "San José de Los Llanos",
	},
	{
		value: "Cotuí",
		label: "Cotuí",
	},
	{
		value: "Cevicos",
		label: "Cevicos",
	},
	{
		value: "Fantino",
		label: "Fantino",
	},
	{
		value: "La Mata",
		label: "La Mata",
	},
	{
		value: "Bisonó",
		label: "Bisonó",
	},
	{
		value: "Jánico",
		label: "Jánico",
	},
	{
		value: "Licey al Medio",
		label: "Licey al Medio",
	},
	{
		value: "Puñal",
		label: "Puñal",
	},
	{
		value: "Sabana Iglesia",
		label: "Sabana Iglesia",
	},
	{
		value: "San José de las Matas",
		label: "San José de las Matas",
	},
	{
		value: "Tamboril",
		label: "Tamboril",
	},
	{
		value: "Villa González",
		label: "Villa González",
	},
	{
		value: "San Ignacio de Sabaneta",
		label: "San Ignacio de Sabaneta",
	},
	{
		value: "Los Almácigos",
		label: "Los Almácigos",
	},
	{
		value: "Monción",
		label: "Monción",
	},
	{
		value: "Santo Domingo Este",
		label: "Santo Domingo Este",
	},
	{
		value: "Boca Chica",
		label: "Boca Chica",
	},
	{
		value: "Los Alcarrizos",
		label: "Los Alcarrizos",
	},
	{
		value: "Pedro Brand",
		label: "Pedro Brand",
	},
	{
		value: "San Antonio de Guerra",
		label: "San Antonio de Guerra",
	},
	{
		value: "Santo Domingo Norte",
		label: "Santo Domingo Norte",
	},
	{
		value: "Santo Domingo Oeste",
		label: "Santo Domingo Oeste",
	},
	{
		value: "Mao",
		label: "Mao",
	},
	{
		value: "Esperanza",
		label: "Esperanza",
	},
	{
		value: "Laguna Salada",
		label: "Laguna Salada",
	},
];

export const facturaRequierePin = (factura) => {
	return factura.credito_disponible < 0;
};

export const validarPINSeguridad = async (pin) => {
	const response = await apiRequest(
		null,
		`validate-pin_seguridad?pin_seguridad=${pin}`,
		"GET"
	);

	if (response.success) {
        const event = new CustomEvent('pinValidado', {detail: response.data});
        window.dispatchEvent(event);

        return response.data;
    }

	toast("Error consultando PIN", "error");
};

export const getZonasForDropdown = async () => {
  let response = await request("rutas?limit=300");

  return [
    { value: "", label: "Todas" },
    ...response.data.map((zona) => ({
      value: zona?.id,
      label: `${zona?.nombre} ${zona?.descripcion}`,
    })),
  ];
};

export const getVendedoresForDropdown = async () => {
  const response = await request("vendedores?limit=100");
  const filteredResponse = response.data.filter(vendedor => vendedor !== null);
  return [
    { value: "", label: "Todos" },
    ...filteredResponse.map((vendedor) => ({
      value: vendedor?.id || null,
      label: `${vendedor?.nombre} ${vendedor?.apellido}`,
    }))
  ];
};

export const getSuplidoresForDropdown = async (e = null) => {
	const response = await request("contactos?tipo=proveedor&limit=100&buscar="+e);
	return response.data.map(c => ({value: c.id, label: c.nombre}));
};

export const getCuentasForDropdown = async (term = null) => {
    const path = term ? "cuentas?buscar="+term : "cuentas";
	const response = await request(path);
	return response.data.map(cuenta => ({value: cuenta.id, label: cuenta.nombre}));
};

export const getCategoriasForDropdown = async (tipo = 'Categorías', relacion = 'Facturas', buscar = '') => {
  const response = await request(`taxonomias?tipo=${tipo}&relacion=${relacion}&buscar=${buscar}`);

  return [
    ...response.data.map((categoria) => ({
      value: categoria?.id,
      label: categoria?.nombre,
    })),
  ];
};

export const getPlazosForDropdown = async () => {
	const response = await request(`terminos-pago`);

	return [
	  ...response.data.map((plazo) => ({
		value: plazo?.id,
		label: plazo?.nombre,
	  })),
	];
  };

export const getOptionsForDropdown = async (url = null, label = 'nombre', value = 'id', includeTodos = false) => {
    const table = await apiRequest({}, url, 'get');
    if (table.data) {
        const data = table.data.map(item => ({value: item[value], label: item[label]}));
        if (includeTodos) {
            data.unshift({label: 'Todos', value: ""});
        }
        return data;
    }
};

export const isVendedor = () => {
	const rol =  auth.getUser().rol;

	//soporte para Roles
	if (rol) {
		const isVendedor = rol.includes("vendedor") || rol.includes("cobrador");

		// si tiene rol de vendedor retornar true else validar es_vendedor
		if (isVendedor) return isVendedor;
	}

	return auth.getUser().es_vendedor;
};

export const isCobrador = () => {
	return auth.getUser().rol?.includes("cobrador")  || false;
};

export const isPastDate = (date, dateToCompare) => {
	if(!!dateToCompare) {
		return dayjs(dateToCompare).startOf("day") > dayjs(date).endOf("day");
	}
	return dayjs().startOf("day") > dayjs(date).endOf("day");

}

export const renderEstadoBadge = (estado) => {
	if (estado === "pendiente") {
		return (
			<span
				className="badge badge-secondary p-2"
			>
				Pendiente
			</span>
		);
	} else if (estado === "pagada") {
		return (
			<span
				className="badge badge-success p-2"
			>
				Pagada
			</span>
		);
	} else if (estado === "completada") {
		return (
			<span
				className="badge badge-success p-2"
			>
				Completada
			</span>
		);
	}

	return <span className="badge badge-danger p-2">Cancelada</span>;
};

// This function returns given value without rounding
// EX: 100.006.toFixed(2) => 100.05. (this is not what we need).
// This function: money(100.006) => 100.00 or 100
// This will help us when we need to calculate money and be sure that what we see in the screen is the same that we are calculating.
export const money = (value = 0) => {
	if(!value) return 0;
    return parseFloat(value).toFixed(2);
};

export const pad = (number) =>  {
    return number > 9 ? number : '0' + (number || 0);
}

export const diferenciaEnSegundos = (fecha) => {
    return dayjs().diff(fecha, 's');
}

export const contador = (segundos) => {
	const seg = Math.abs(segundos);
    const h = Math.floor(seg / 3600);
    const m = Math.floor((seg - h * 3600) / 60 );
    const s = seg - ( h * 3600 + m * 60);

    return `${pad(h)}:${pad(m)}:${pad(s)}`;
}

export const formasPagoCompras = [
    '01 - EFECTIVO',
    '02 - CHEQUES/TRANSFERENCIAS',
    '03 - TARJETA DE CREDITO/DEBITO',
    '04 - COMPRA A CREDITO',
    '05 - PERMUTA',
    '06 - NOTA DE CREDITO',
    '07 - MIXTO',
];

export const tiposGastosCompras = [
    '01 - GASTOS DE PERSONAL',
    '02 - GASTOS POR TRABAJO/SUMINISTRO/SERVICIO',
    '03 - ARRENDAMIENTOS',
    '04 - GASTOS DE ACTIVOS FIJOS',
    '05 - GASTOS DE REPRESENTACION',
    '06 - OTRAS DEDUCIONES ADMINTIDAS',
    '07 - GASTOS FINACIEROS',
    '08 - GASTOS EXTRAORDINARIOS',
    '09 - COMPRAS Y GASTOS',
    '10 - ADQUISISION DE ACTIVOS',
];

export const composeCSV = (data) => {
    if (data.length > 0) {
        const headers = Object.keys(data[0]);
        const body = data.map(d => Object.values(d));
        body.unshift(headers);
        return body;
    }

    return [];
}

export const composeTXT = (data, reporte, desde) => {
    if (data.length > 0) {
        const body = data.map(d => Object.values(d));
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        body.unshift([
            reporte,
            sucursal.rnc?.replace(/[^\d]/g, ''),
            dayjs(desde).format("YYYYMM"),
            body.length
        ]);
        return body;
    }

    return [];
}

/**
 * Convert a 2D array into a CSV string
 */
export const arrayToCsv = (data, separator = ',', extension = "csv") => {
    return data.map(row =>
      row
      .map(String)  // convert every value to String
      .map(v => v.replaceAll('"', '""'))  // escape double quotes
      .map(v => extension == 'csv' ? `"${v}"` : v)  // quote it
      .join(separator)
    ).join('\r\n');  // rows starting on new lines
}

/**
 * Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
export const downloadBlob = (content, filename, contentType = 'text/csv;charset=utf-8;') => {
    // Create a blob
    var blob = new Blob([content], { type: contentType });
    var url = URL.createObjectURL(blob);

    // Create a link to download it
    var pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
}

export const triggerEvent = (evento, data = {}) => {
    const event = new CustomEvent(evento, {detail: data});
    document.dispatchEvent(event);
}

export const catchEvent = (evento, accion = () => {}) => {
    document.addEventListener(evento, e => {
        accion(e.detail);
    });
}

export const modalidadAusenciaForDropdown = [
	{ value: "normal_pagas", label: "Normal Pagas" },
	{ value: "no_pagas", label: "No Pagas" },
	{ value: "trabajadas_normal_pagas", label: "Trabajadas + Normal Pagas" },
	{ value: "trabajadas_no_pagas", label: "Trabajadas + No Pagas" },
	{ value: "ninguna", label: "Ninguna" },
];

export const estadoAusenciaForDropdown = [
	{ value: "Todos", label: "Todos" },
	{ value: "Cancelada", label: "Cancelada" },
	{ value: "Completada", label: "Completada" },
	{ value: "Pendiente", label: "Pendiente" },
	{ value: "En curso", label: "En curso" },
];

export const getModalidadAusencia = (modalidad) => modalidadAusenciaForDropdown.reduce((p, item) => item.value === modalidad ? item : p, null);

export const permisions = {
	abrirTarjeta: 'abrir-tarjetas',
	editarInventario: 'editar-inventario',
	editarProductos: 'editar-productos',
	editarSucursales: 'editar-sucursales',
	editarUsuarios: 'editar-usuarios',
	verAcademico: 'ver-academico',
	verCompras: 'ver-compras',
	verComprobantes: 'ver-comprobantes',
	verConfiguracion: 'ver-configuracion',
	verConsultas: 'ver-consultas',
	verContabilidad: 'ver-contabilidad',
	verContactos: 'ver-contactos',
	verContratos: 'ver-contratos',
	verCotizaciones: 'ver-cotizaciones',
	verCuentas: 'ver-cuentas',
	verCuentasPorCobrar: 'ver-cuentas-por-cobrar',
	verCuentasPorPagar: 'ver-cuentas-por-pagar',
	verDashboard: 'ver-dashboard',
	verDescuentos: 'ver-descuentos',
	verEmpleados: 'ver-empleados',
	verEstudiantes: 'ver-estudiantes',
	verEvaluaciones: 'ver-evaluaciones',
	verFacturacionAcademica: 'ver-facturacion-academica',
	verFacturas: 'ver-facturas',
	verFacturasRecurrentes: 'ver-facturas-recurrentes',
	verGastos: 'ver-gastos',
	verGastosGenerales: 'ver-gastos-generales',
	verGastosInternos: 'ver-gastos-internos',
	verGiGrupos: 'ver-gi-grupos',
	verGiPrestamos: 'ver-gi-prestamos',
	verGiServidores: 'ver-gi-servidores',
	verGrupoTiposEmpleados: 'ver-grupo-tipos-empleados',
	verIncentivos: 'ver-incentivos',
	verIngresos: 'ver-ingresos',
	verInscripcion: 'ver-inscripcion',
	verInventario: 'ver-inventario',
	verNomina: 'ver-nomina',
	verNotasCredito: 'ver-notas-credito',
	verOrdenesCompra: 'ver-ordenes-compra',
	verPadrestutores: 'ver-padres-tutores',
	verProductos: 'ver-productos',
	verProgramacion: 'ver-programacion',
	verRecibosIngreso: 'ver-recibos-ingreso',
	verReclamaciones: 'ver-reclamaciones',
	verReportes: 'ver-reportes',
	verRutas: 'ver-rutas',
	verServicios: 'ver-servicios',
	verSolicitudes: 'ver-solicitudes',
	verTipos: 'ver-tipos',
	verVehiculos: 'ver-vehiculos',
	verPlantillasImpresion: 'ver-plantillas-impresion',
	verCocurriculares: 'ver-cocurriculares',
	verCobros: 'ver-cobros',
	verInscripcionCocurriculares: 'ver-inscripcion-cocurriculares',
	verAusencias: 'ver-ausencias',
};
